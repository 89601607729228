import React, { useState } from 'react'
import PropTypes from 'prop-types'

import Lightbox from 'react-image-lightbox'
import Img from 'gatsby-image'

const Gallery = ({
    title,
    content,
    images,
    largeImages,
}) => {
    const [index, setIndex] = useState(0)
    const [isOpen, setIsOpen] = useState(false)

    const nextImage = () => {
        setIndex((index + 1) % largeImages.length)
    }

    const prevImage = () => {
        setIndex((index + largeImages.length - 1) % largeImages.length)
    }

    const openImage = (imageIndex) => {
        setIndex(imageIndex)
        setIsOpen(true)
    }

    return (
        <div className="gallery">
            <h3>{title}</h3>
            <div className="gallery-images">
                {images.map((image, i) => (
                    <div className="gallery-preview" key={i} onClick={() => openImage(i)}>
                        <Img fixed={image.file.childImageSharp.large}  />
                    </div>
                ))}
            </div>
            

            {isOpen && (
                <Lightbox
                    mainSrc={largeImages[index]}
                    nextSrc={largeImages[(index + 1) % largeImages.length]}
                    prevSrc={largeImages[(index + largeImages.length - 1) % largeImages.length]}
                    imageTitle={images[index].title}
                    onCloseRequest={() => setIsOpen(false)}
                    onMovePrevRequest={prevImage}
                    onMoveNextRequest={nextImage}
                />
            )}
        </div>
    )
}

Gallery.defaultProps = {
    images: [],
    largeImages: [],
}

Gallery.propTypes = {
    images: PropTypes.array,
    largeImages: PropTypes.array,
}

export default Gallery
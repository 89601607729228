import React from "react"
import { graphql } from "gatsby"
import Helmet from "react-helmet"

import Layout from "../components/Layout"
import Gallery from "../components/Gallery"
import Sidebar from "../components/Sidebar"

import 'react-image-lightbox/style.css'

class WorkRoute extends React.Component {
    render() {
      const galleries = []
      const { title, subtitle } = this.props.data.site.siteMetadata
      const sets = this.props.data.sets.edges



      sets.forEach((set, i) => {
        galleries.push(
            <Gallery 
                key={i}
                title={set.node.frontmatter.title}
                content={set.node.html}
                images={set.node.frontmatter.images} 
            />
        )
      })
  
      return (
        <Layout>
          <div>
            <Helmet>
              <title>{title}</title>
              <meta name="description" content={subtitle} />
            </Helmet>
            <Sidebar {...this.props} />
            <div className="content">
              <div className="content__inner">
                    <div className="page work">
                        <h1 className="page__title">Work</h1>
                        <div className="page__body">
                            <div className="row">
                                <div className="column">
                                    <ul className="timeline">
                                        <li>
                                            <span>
                                                <h4>Sainsbury's</h4>
                                                <p>Software Engineering Manager</p>
                                                <p className="date">2015&mdash;now</p>
                                            </span>
                                        </li>
                                        
                                        <li>
                                            <span>
                                                <h4>Bauer Media</h4>
                                                <p>Lead Developer</p>
                                                <p className="date">2012&mdash;2015</p>
                                            </span>
                                        </li>
                                        <li>
                                            <span>
                                                <h4>Vanilla Storm</h4>
                                                <p>Web Developer</p>
                                                <p className="date">2010&mdash;2012</p>
                                            </span>
                                        </li>
                                        <li>
                                            <span>
                                                <h4>British Waterways</h4>
                                                <p>Web Developer</p>
                                                <p className="date">2008&mdash;2010</p>
                                            </span>
                                        </li>
                                    </ul>
                                </div>
                                <div className="column speaking">
                                    <h3>Speaking</h3>
                                    <div className="talk">
                                        <h5>A Full Stack Introduction to GraphQL</h5>
                                        <p>Manchester Web Meetup</p>
                                        <p className="date">September 2018</p>
                                    </div>
                                    <div className="talk">
                                        <h5>Building GraphQL Applications with Relay</h5>
                                        <p>GraphQLMcr</p>
                                        <p className="date">May 2018</p>
                                    </div>
                                    
                                    <div className="talk">
                                        <h5>GraphQL is good for your health</h5>
                                        <p>MancJS</p>
                                        <p className="date">March 2018</p>
                                    </div>
                                </div>
                            </div>
                            <h2 id="sketchnotes">Sketchnotes</h2>
                            {galleries}      
                        </div>
                    </div>
              </div>
            </div>
          </div>
        </Layout>
      )
    }
  }
  
  export default WorkRoute
  
  export const pageQuery = graphql`
    query {
      site {
        siteMetadata {
          title
          subtitle
          copyright
          menu {
            label
            path
          }
          author {
            name
            email
            twitter
            github
          }
        }
      }
      sets: allMarkdownRemark(
        sort: { fields: [frontmatter___date], order: DESC }
        filter: { frontmatter: { type: { in: ["sketchnote"] } } }
      ) {
        edges {
          node {
            html
            fields {
              slug
            }
            frontmatter {
              title
              images {
                title
                file {
                  childImageSharp {
                    small: fixed(width: 400){
                        ...GatsbyImageSharpFixed
                    }
                    large: fluid(maxWidth: 1600) {
                        ...GatsbyImageSharpFluid
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  `
import { compose, mapProps } from 'recompose'

import Gallery from './Gallery'

const getImageLists = ({ images, title, content }) => {
    return {
        images,
        title,
        content,
        largeImages: images.map(image => (
            image.file.childImageSharp.large.src
        ))
    }
}

export default compose(
    mapProps(getImageLists),
)(Gallery)

